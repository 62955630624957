import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  Tooltip,
  CircularProgress,
  IconButton,
  Typography,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useFeedbackContext } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import {
  rootStateInterface,
  bestOneCommonQueryParamsStateInterface,
  mapStateToPropsBestOneCommonWithQueryParams,
} from '../../../../reducers';
import { NotificationBlock } from '../notifications/utils';
import { createErrorNotification } from '../notifications/util';
import { UpdatableCallList } from '../../../../__generated__/globalTypes';
import {
  ADD_EXTERNAL_CONTACT_TO_CALL_LIST_MUTATION,
  GET_CALL_LIST_QUERY,
} from './CallList';
import {
  ADD_BTP_TO_CALL_LIST_MUTATION,
  ADD_SHIP_TO_TO_CALL_LIST_MUTATION,
} from '../notes/editCell/edit.note.call.list';
import { addMyContactBTPToCallList } from '../../../app/_CustomerMainPage/__generated__/addMyContactBTPToCallList';
import {
  ADD_CONTACT_SHIP_TO_TO_CALL_LIST_MUTATION,
  ADD_MY_CONTACT_BTP_TO_CALL_LIST_MUTATION,
} from '../../../app/_CustomerMainPage/CustomerMainPage';
import { addContactShipToToCallList } from '../../../app/_CustomerMainPage/__generated__/addContactShipToToCallList';
import { addBusinessTradingPartnerToCallList } from '../notes/editCell/__generated__/addBusinessTradingPartnerToCallList';
import { addShipToToCallListMutation } from '../notes/editCell/__generated__/addShipToToCallListMutation';
import { AddExternalContactToCallList } from './__generated__/AddExternalContactToCallList';

type PropsType = {
  BTP_ShipTo_Prospects?: string;
  selectedRows: any[];
  setSelectedRows?: any;
};

type stateProps = bestOneCommonQueryParamsStateInterface & PropsType;

const AddToCallListButton: FC<stateProps> = ({
  selectedRows,
  BTP_ShipTo_Prospects,
  setSelectedRows,
}) => {
  const [pending, setPending] = useState(false);
  const { addFeedbackMessage } = useFeedbackContext();

  const { addErrorMessage } = useFeedbackContext();

  const { data: callListData } = useQuery<any>(GET_CALL_LIST_QUERY);

  const [addBusinessTradingPartnerToCallListMutation] =
    useMutation<addBusinessTradingPartnerToCallList>(
      ADD_BTP_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(e.message, ADD_BTP_TO_CALL_LIST_MUTATION);
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const [addShiptoToCallListMutation] =
    useMutation<addShipToToCallListMutation>(
      ADD_SHIP_TO_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(e.message, ADD_SHIP_TO_TO_CALL_LIST_MUTATION);
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const [addMyContactBTPToCallListMutation] =
    useMutation<addMyContactBTPToCallList>(
      ADD_MY_CONTACT_BTP_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(e.message, ADD_MY_CONTACT_BTP_TO_CALL_LIST_MUTATION);
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const [addContactShipToToCallListMutation] =
    useMutation<addContactShipToToCallList>(
      ADD_CONTACT_SHIP_TO_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(e.message, ADD_CONTACT_SHIP_TO_TO_CALL_LIST_MUTATION);
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const [addExternalContactToCallListMutation] =
    useMutation<AddExternalContactToCallList>(
      ADD_EXTERNAL_CONTACT_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(
            e.message,
            ADD_EXTERNAL_CONTACT_TO_CALL_LIST_MUTATION
          );
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const callList = callListData?.BTP_CALL_LIST?.result || [];

  function removeDuplicates() {
    const newCallListItems = selectedRows.filter(
      ({ publicId: id1 }) =>
        !callList?.some(({ callListRelationPublicId: id2 }: any) => id2 === id1) // TODO We have to change the type when bff is fixed
    );

    processCallList(newCallListItems);
  }

  async function processCallList(newCallListItems: any[]) {
    const missingContact: Array<any> = [];
    const missingPrimaryContact: Array<any> = [];
    const customerType = BTP_ShipTo_Prospects || 'unknown';
    const addToCallListObjects: UpdatableCallList[] = [];

    newCallListItems.forEach((checkedInfo: any) => {
      if (checkedInfo.hasContacts === true && checkedInfo.primaryContact) {
        const data: UpdatableCallList = {
          ownerPublicId: checkedInfo.publicId,
        };
        addToCallListObjects.push(data);
      } else if (checkedInfo.hasContacts === false) {
        missingContact.push(checkedInfo);
      } else if (!checkedInfo.primaryContact) {
        missingPrimaryContact.push(checkedInfo);
      }
    });
    const blocks: Array<NotificationBlock> = [];
    if (missingContact.length) {
      blocks.push({
        title: `The following ${customerType} doesn't have any contact`,
        items: missingContact,
        toText,
      });
    }
    if (missingPrimaryContact.length) {
      blocks.push({
        title: `The following ${customerType} doesn't have a primary contact`,
        items: missingPrimaryContact,
        toText,
      });
    }

    if (blocks.length) {
      const btpWithNoContactEmail = blocks.map((title) => {
        return (
          <>
            <Typography variant="h3">{title.title}</Typography>
            {title.items.map((e) => (
              <ListItem key={e.publicId}>
                <ListItemText primary={`- ${e.name} (${e.number})`} />
              </ListItem>
            ))}
          </>
        );
      });
      addFeedbackMessage(btpWithNoContactEmail, 'warning');
    }
    if (addToCallListObjects.length > 0) {
      try {
        await Promise.resolve(
          addToCallList(
            customerType,
            'not-used',
            addToCallListObjects,
            addBusinessTradingPartnerToCallListMutation,
            addShiptoToCallListMutation,
            addMyContactBTPToCallListMutation,
            addContactShipToToCallListMutation,
            addExternalContactToCallListMutation
          )
        );
      } catch (e) {
        createErrorNotification(e);
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('Nothing added to the call list');
    }
  }

  const handleClick = () => {
    if (BTP_ShipTo_Prospects === 'BTP' || BTP_ShipTo_Prospects === 'ShipTo') {
      try {
        setPending(true);
        removeDuplicates();
      } finally {
        setPending(false);
        if (setSelectedRows) setSelectedRows([]);
      }
    }

    return null;
  };

  return (
    <Tooltip title="Add to call list" aria-label="add">
      <IconButton size="medium" sx={{ backgroundColor: 'secondary.main' }}>
        {pending && <CircularProgress />}
        {!pending && (
          <PlaylistAddIcon
            style={{ fontSize: 20, color: 'white' }}
            onClick={handleClick}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};
const mapStateToProps = (state: rootStateInterface) =>
  mapStateToPropsBestOneCommonWithQueryParams(state);

export const addToCallList = (
  contactType: string,
  contactTypeDisplayName: string,
  updateParams: UpdatableCallList[],
  addBusinessTradingPartnerToCallListMutation: any,
  addShiptoToCallListMutation: any,
  addMyContactBTPToCallListMutation: any,
  addContactShipToToCallListMutation: any,
  addExternalContactToCallListMutation: any
) => {
  if (isBTP(contactType)) {
    addBusinessTradingPartnerToCallListMutation({
      variables: {
        updatableCallList: updateParams,
      },
    });
  } else if (isShipTo(contactType)) {
    addShiptoToCallListMutation({
      variables: {
        updatableCallList: updateParams,
      },
    });
  } else if (isContact(contactType)) {
    if (isBTP(contactTypeDisplayName)) {
      addMyContactBTPToCallListMutation({
        variables: {
          updatableCallList: updateParams,
        },
      });
    } else if (isShipTo(contactTypeDisplayName)) {
      addContactShipToToCallListMutation({
        variables: {
          updatableCallList: updateParams,
        },
      });
    } else if (isExternalContact(contactTypeDisplayName)) {
      addExternalContactToCallListMutation({
        variables: {
          updatableCallList: updateParams,
        },
      });
    } else {
      throw new Error('Error trying to add to the call list');
    }
  }
};

export const getContactType = (contactType: string) => {
  const normalize = contactType
    .toUpperCase()
    .replaceAll('_', '')
    .replaceAll(' ', '');
  return normalize;
};

export const isBTP = (contactType: string) => {
  const ctype = getContactType(contactType);
  return ctype === 'BUSINESSTRADINGPARTNER' || ctype === 'BTP';
};
export const isShipTo = (contactType: string) =>
  getContactType(contactType) === 'SHIPTO';
export const isProspect = (contactType: string) =>
  getContactType(contactType) === 'PROSPECT';
export const isContact = (contactType: string) =>
  getContactType(contactType) === 'CONTACT';
export const isExternalContact = (contactType: string) =>
  getContactType(contactType) === 'EXTERNALCONTACT';

export const toText = (item: any) => `${item.name} (${item.number})`;

export default connect(mapStateToProps)(AddToCallListButton);
