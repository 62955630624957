import React, { FC, useState } from 'react';
import { Typography, Tooltip, Popover, Box } from '@mui/material';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import {
  Column,
  CustomColumn,
  CustomTable,
} from '@bestseller-bit/sales-and-invoicing.ui-elements.table';
import {
  ApolloCache,
  DefaultContext,
  LazyQueryExecFunction,
  MutationFunction,
  OperationVariables,
} from '@apollo/client';
import { muiRenderEditableNoteColumnCallList } from '../tables/render.table.columns/mui.render.editable.note.column.call.list';
import EditExternalContact from '../manage.contacts/mui.edit.external.contacts';
import SelectPrimaryPhone from '../contact.phonelist/mui.contact.phonelist.select';
import CallListActions from './call.list.actions';
import { DateOptions } from '../../../../utility/DateTime';
import {
  getCallList,
  getCallList_getMyCallList_result,
} from './__generated__/getCallList';
import { AddExternalContactToCallList } from './__generated__/AddExternalContactToCallList';

type PropsType = {
  loadData?: getCallList_getMyCallList_result[] | null;
  selectedState: [
    getCallList_getMyCallList_result[],
    React.Dispatch<React.SetStateAction<getCallList_getMyCallList_result[]>>
  ];
  salesPersonPublicId: string;
  handleClickRedirection: (row: getCallList_getMyCallList_result) => void;
  getCallListQuery: LazyQueryExecFunction<getCallList, OperationVariables>;
  addExternalContactToCallListMutation: MutationFunction<
    AddExternalContactToCallList,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
};

type ComponentProps = PropsType;
const CallListTable: FC<ComponentProps> = ({
  loadData,
  selectedState: [selected, setSelected],
  handleClickRedirection,
  salesPersonPublicId,
  getCallListQuery,
  addExternalContactToCallListMutation,
}) => {
  const [sortBy] = useState<keyof getCallList_getMyCallList_result>('name');
  const [orderByKey, setOrderByKey] =
    useState<keyof getCallList_getMyCallList_result>(sortBy);
  const [open, setOpen] = useState<boolean>(false);
  const [rowValue, setRowValue] = useState<getCallList_getMyCallList_result>();
  const [anchorEl, setAnchorEl] = useState(null);
  const isAnchorEl = Boolean(anchorEl);
  const id = isAnchorEl ? 'simple-popover' : undefined;

  function handleEdit(rowData: getCallList_getMyCallList_result) {
    setOpen(true);
    setRowValue(rowData);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAnchorEl = () => {
    setAnchorEl(null);
  };

  const handleClickAnchorEl = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const callListColumninfo: Column<
    getCallList_getMyCallList_result,
    keyof getCallList_getMyCallList_result
  >[] = [
    {
      key: 'number',
      header: 'Number',
      render: (model) =>
        model.number && model.contactTypeDisplayName !== 'External Contact' ? (
          <Typography
            variant="body2"
            onClick={() => handleClickRedirection(model)}
          >
            {model.number}
          </Typography>
        ) : (
          model.number
        ),
      width: 100,
    },
    {
      key: 'name',
      header: 'Name',
      render: (model) =>
        !model.name && model.contactTypeDisplayName === 'External Contact' ? (
          model.contactTypeDisplayName
        ) : model.contactTypeDisplayName !== 'External Contact' ? (
          <Typography
            variant="body2"
            onClick={() => handleClickRedirection(model)}
          >
            {model.name}
          </Typography>
        ) : (
          model.name
        ),
    },
    {
      key: 'contactName',
      header: 'Contact',
      render: (model) =>
        model.contactTypeDisplayName === 'External Contact' ? (
          <Tooltip title="Edit this external contact">
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={() => handleEdit(model)}
            >
              {model.contactName}
            </Typography>
          </Tooltip>
        ) : (
          model.contactName
        ),
    },
  ];

  const customColumns: CustomColumn<getCallList_getMyCallList_result>[] = [
    {
      header: 'Actions',
      render: (model) => <CallListActions row={model} />,
    },
    {
      header: 'Called',
      render: (model) =>
        model.lastCallDate &&
        new Date(model.lastCallDate)
          .toLocaleString(DateOptions.locale)
          .substring(0, 17),
      position: 5,
    },
    {
      header: 'Phone',
      render: (model) => (
        <SelectPrimaryPhone
          contactPhoneList={model.contactPhoneNumber || []}
          contactTypeDisplayName={model.contactTypeDisplayName || ''}
          contactPublicId={model.contactPublicId || ''}
          enableDropDown={false}
          enableCall
          salesPersonPublicId={salesPersonPublicId}
          cellRow={model}
          calledFromCallList
        />
      ),
      position: 4,
    },
    {
      header: 'Note',
      render: (model) =>
        muiRenderEditableNoteColumnCallList({
          rowData: model,
          cellData: model.note,
          addExternalContactToCallListMutation,
        }),
      position: 6,
    },
    {
      render: (model) => (
        <Box>
          <Tooltip
            title={`Added by ${model.createdByUserName} on ${model.createdDate} `}
          >
            <RecentActorsIcon
              fontSize="small"
              onClick={handleClickAnchorEl}
              sx={{ mt: 1 }}
            />
          </Tooltip>
          <Popover
            id={id}
            open={isAnchorEl}
            anchorEl={anchorEl}
            onClose={handleCloseAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography sx={{ padding: 2 }}>
              Added by {model.createdByUserName} on {model.createdDate}
            </Typography>
          </Popover>
        </Box>
      ),
      position: 0,
    },
  ];

  return (
    <>
      <CustomTable
        data={loadData || []}
        columns={callListColumninfo}
        customColumns={customColumns}
        selectedModelsState={[selected, setSelected]}
        orderByState={[orderByKey, setOrderByKey]}
        height="20rem"
        isWhiteBackground
      />
      {open && (
        <EditExternalContact
          rowData={rowValue}
          openModal={[open, setOpen]}
          onClose={handleClose}
          getCallListQuery={getCallListQuery}
        />
      )}
    </>
  );
};
export default CallListTable;
