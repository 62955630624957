import { UpdatableCallList } from '../../../__generated__/globalTypes';
import {
  apiRegisterContactCall,
  registerContactCallQueryProps,
} from './api/register.contact.call.api';

export const registerContactCallService = (
  queryParams: registerContactCallQueryProps
) => {
  return apiRegisterContactCall(queryParams).then((response: any) => {
    return response;
  });
};

export const registerCallCheckingCallListService = (
  lastCallParams: registerContactCallQueryProps,
  updateParams: UpdatableCallList,
  contactType: string,
  contactTypeDisplayName: string,
  calledFromCallList: boolean,
  addToCallList: any
) => {
  return new Promise((resolve) => {
    registerContactCallService(lastCallParams).then(() => {
      if (calledFromCallList === true) {
        // eslint-disable-next-line no-param-reassign
        updateParams.lastCallDate = new Date();
        addToCallList(contactType, contactTypeDisplayName, updateParams).then(
          (result: unknown) => {
            resolve(result);
          }
        );
      } else {
        resolve(null);
      }
    });
  });
};
