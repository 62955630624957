import { FetchResult } from 'apollo-link';
import { apolloClient } from '../../../../utility/ApolloConfig';
import { registerContactCallMutation } from '../../../../mutations/app/register.contact.call/register.contact.call.mutation';
import { registerContactCallMutationInterface } from '../../../../mutations/app/register.contact.call/register.contact.call.mutation.interface';
import { GET_CALL_LIST_QUERY } from '../../../../application/Shared/_utilities/call.list/CallList';

export type registerContactCallQueryProps = {
  contactPublicId: string;
  contactPhonePublicId: string;
};

export const apiRegisterContactCall = (
  queryParams: registerContactCallQueryProps
) => {
  const { contactPublicId, contactPhonePublicId } = queryParams;
  return apolloClient
    .mutate({
      mutation: registerContactCallMutation,
      variables: { contactPublicId, contactPhonePublicId },
      refetchQueries: [GET_CALL_LIST_QUERY],
    })
    .then(
      (response: FetchResult<registerContactCallMutationInterface>) =>
        response.data
    );
};
