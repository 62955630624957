import { gql } from '@apollo/client';

export const CallListResultFragment = gql`
  fragment CallListResult on MyCallListResult {
    queryCount
    result {
      createdByUserName
      createdDate
      contactType
      contactTypeDisplayName
      callListRelationPublicId
      number
      name
      contactPublicId
      contactName
      entityPublicId
      contactPhoneNumber {
        publicId
        countryPublicId
        countryCode
        phoneNumber
        isPrimaryPhone
        changedBy
        changedDate
      }
      note
      lastCallDate
      publicId
    }
  }
`;
