import React from 'react';
import EditNoteCallList from '../../notes/editCell/edit.note.call.list';

export const muiRenderEditableNoteColumnCallList = ({
  rowData,
  cellData,
  addExternalContactToCallListMutation,
}: {
  rowData: any;
  cellData: any;
  addExternalContactToCallListMutation: any;
}) => {
  return (
    <EditNoteCallList
      key={cellData}
      cellRow={rowData}
      cellData={cellData}
      addExternalContactToCallListMutation={
        addExternalContactToCallListMutation
      }
    />
  );
};
