import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import { Badge, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { CustomDialog } from '@bestseller-bit/sales-and-invoicing.ui-elements.dialog';
import { useFeedbackContext } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import gql from 'graphql-tag';
import {
  LazyQueryExecFunction,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { rootStateInterface } from '../../../../reducers';
import CallListTable from './mui.call.list.table';
import CreateExternalContact from '../create.external.contact/mui.create.external.contact';
import MuiCallListPanelHelper from '../panels/mui.callist.panel.helper';
import { handleStopImpersonate } from '../impersonate/mui.stop.impersonate.menu';
import {
  IMPERSONATE_USER,
  SET_NOTIFICATION,
} from '../../../../constants/shared/bestone.global.constants';
import { SHOW_KEY_FIGURES } from '../../../../constants/app/app.show.btp.mycustomers.page.constants';
import { userImpersonateInterface } from '../impersonate/impersonate.utils';
import PathConstants from '../../../../routes/pathConstants';
import { getKeyFigures } from '../../../app/_CustomerMainPage/__generated__/getKeyFigures';
import {
  getCallList,
  getCallList_getMyCallList,
  getCallList_getMyCallList_result,
} from './__generated__/getCallList';
import { CallListResultFragment } from './callListResultFragment';
import { RemoveContactsFromCallList } from './__generated__/RemoveContactsFromCallList';
import { AddExternalContactToCallList } from './__generated__/AddExternalContactToCallList';
import { CallListResult_result } from './__generated__/CallListResult';

export type CallListType = {
  keyFiguresData: getKeyFigures | undefined;
  getCallListQuery: LazyQueryExecFunction<getCallList, OperationVariables>;
  callListData: getCallList_getMyCallList | undefined;
  callListLoading: boolean;
};

const CallList: FC<CallListType> = ({
  keyFiguresData,
  getCallListQuery,
  callListData,
  callListLoading,
}) => {
  const {
    currentQueryUserName,
    currentUserQueryId,
    impersonating,
    currentUserWholeData,
  } = useSelector(
    (state: rootStateInterface) => state.application.shared.bestoneCommon
  );
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCreateContact, setOpenCreateContact] = useState<boolean>(false);
  const [selected, setSelected] = useState<getCallList_getMyCallList_result[]>(
    []
  );
  const [confirmDelete, setConfirmDelete] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const { addErrorMessage } = useFeedbackContext();

  const [removeContactsFromCallListMutation] =
    useMutation<RemoveContactsFromCallList>(
      REMOVE_CONTACTS_FROM_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(e.message, REMOVE_CONTACTS_FROM_CALL_LIST_MUTATION);
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const [addExternalContactToCallListMutation] =
    useMutation<AddExternalContactToCallList>(
      ADD_EXTERNAL_CONTACT_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(
            e.message,
            ADD_EXTERNAL_CONTACT_TO_CALL_LIST_MUTATION
          );
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const addRowToSelected = (row: getCallList_getMyCallList_result) => {
    if (selected.includes(row)) {
      setSelected((previousValue) =>
        previousValue.filter((value) => {
          return value !== row;
        })
      );
    } else setSelected((previousValue) => [...previousValue, row]);
  };
  const isSelected = (row: getCallList_getMyCallList_result) =>
    selected
      .map((selection) => selection.contactPublicId)
      .includes(row.contactPublicId);
  const callListCount = callListData?.queryCount || 0;

  const handleOpenCreateContact = () => {
    setOpenCreateContact(true);
  };

  const handleRemoveContacts = () => {
    const deleteCallList = selected.map((callList) => ({
      callListRelationPublicId: callList.callListRelationPublicId || '',
      callListRelationName: callList.contactType || '',
    }));
    removeContactsFromCallListMutation({
      variables: {
        selectedContacts: deleteCallList,
      },
    }).then((result: any) => {
      if (result) {
        getCallListQuery();
      }
    });
    setSelected([]);
    setConfirmDelete(false);
  };

  const handleStopImpersonateClick = () => {
    const notifyError = (payload: any) =>
      dispatch({ type: SET_NOTIFICATION, payload });
    const impersonateUser = (payload: any) =>
      dispatch({ type: IMPERSONATE_USER, payload });
    const keyFigures = (payload: any) =>
      dispatch({ type: SHOW_KEY_FIGURES, payload });
    const impersonateFunctions: userImpersonateInterface = {
      impersonateUser,
      keyFigures,
      getCallListQuery,
    };
    handleStopImpersonate(
      currentUserWholeData.queryUserMail,
      impersonateFunctions,
      keyFiguresData,
      notifyError,
      navigate
    );
    setOpen(false);
  };

  const handleClickRedirection = (row: CallListResult_result) => {
    if (row.contactTypeDisplayName === 'BTP' && row.entityPublicId) {
      const path = PathConstants.BUSINESS_TRADING_PARTNERS_DETAILS.replace(
        ':BTPPublicId',
        row.entityPublicId
      );
      navigate(path);
    } else if (row.contactTypeDisplayName === 'Ship To' && row.entityPublicId) {
      const path = PathConstants.SHIP_TO_DETAILS.replace(
        ':shipToPublicId',
        row.entityPublicId
      );
      navigate(path);
    }
    setOpen(false);
  };

  useEffect(() => {
    getCallListQuery();
  }, [getCallListQuery]);

  const confirmationButtons = [
    {
      label: 'Confirm',
      onClick: handleRemoveContacts,
    },
    {
      label: 'Cancel',
      onClick: () => setConfirmDelete(false),
    },
  ];

  const removeButton = {
    label:
      selected.length !== 0
        ? `Remove contacts (${selected.length})`
        : 'Remove contacts',
    onClick: () => setConfirmDelete(true),
    disabled: selected.length < 1,
  };

  const getButtons = () => {
    if (confirmDelete) {
      return confirmationButtons;
    }
    if (impersonating) {
      return [
        removeButton,
        (isDesktop || !confirmDelete) && {
          label: 'Stop Impersonating',
          onClick: handleStopImpersonateClick,
        },
        {
          label: 'Create contact',
          onClick: handleOpenCreateContact,
        },
        {
          label: 'Cancel',
          onClick: () => setOpen(false),
        },
      ];
    }
    return [
      removeButton,
      {
        label: 'Create contact',
        onClick: handleOpenCreateContact,
      },
      {
        label: 'Cancel',
        onClick: () => setOpen(false),
      },
    ];
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)} sx={{ color: 'common.white' }}>
        <Badge
          key={callListCount}
          color="secondary"
          badgeContent={callListCount}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          overlap="circular"
          showZero={false}
        >
          <PhoneIcon />
        </Badge>
      </IconButton>

      {open && (
        <CustomDialog
          maxWidth="lg"
          minHeight="auto"
          openState={[open, setOpen]}
          onClose={() => setOpen(false)}
          title={`Call List from ${currentQueryUserName}`}
          buttons={{
            text: confirmDelete ? 'Are you sure?' : undefined,
            buttons: getButtons(),
          }}
        >
          {callListLoading && <Loading />}

          {!callListLoading && (
            <>
              {(isDesktop || !confirmDelete) && (
                <CreateExternalContact
                  openDialogState={[openCreateContact, setOpenCreateContact]}
                  getCallListQuery={getCallListQuery}
                  addExternalContactToCallListMutation={
                    addExternalContactToCallListMutation
                  }
                />
              )}

              {callListCount < 1 ? (
                'No contacts to call...'
              ) : isDesktop ? (
                <CallListTable
                  loadData={callListData?.result || null}
                  salesPersonPublicId={currentUserQueryId}
                  handleClickRedirection={handleClickRedirection}
                  selectedState={[selected, setSelected]}
                  getCallListQuery={getCallListQuery}
                  addExternalContactToCallListMutation={
                    addExternalContactToCallListMutation
                  }
                />
              ) : (
                <MuiCallListPanelHelper
                  loadData={callListData || null}
                  salesPersonPublicId={currentUserQueryId}
                  handleClickRedirection={handleClickRedirection}
                  addRowToSelected={addRowToSelected}
                  isSelected={isSelected}
                  isDeleteting={confirmDelete}
                  getCallListQuery={getCallListQuery}
                  addExternalContactToCallListMutation={
                    addExternalContactToCallListMutation
                  }
                />
              )}
            </>
          )}
        </CustomDialog>
      )}
    </>
  );
};

export const GET_CALL_LIST_QUERY = gql`
  query getCallList {
    getMyCallList {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;

export const REMOVE_CONTACTS_FROM_CALL_LIST_MUTATION = gql`
  mutation RemoveContactsFromCallList($selectedContacts: [SelectedContacts]) {
    removeFromCallList(selectedContacts: $selectedContacts) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;

export const ADD_EXTERNAL_CONTACT_TO_CALL_LIST_MUTATION = gql`
  mutation AddExternalContactToCallList(
    $updatableCallList: [UpdatableCallList]
  ) {
    AddExternalContactToCallList(updatableCallList: $updatableCallList) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;

export default CallList;
