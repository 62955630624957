import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Tooltip, IconButton } from '@mui/material';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import UserToImpersonateMenu from '../_utilities/impersonate/mui.user.impersonate.menu';
import { getKeyFigures } from '../../app/_CustomerMainPage/__generated__/getKeyFigures';
import { getCallList } from '../_utilities/call.list/__generated__/getCallList';

type ImpersonateProps = {
  currentUser: string | null;
  impersonating: boolean;
  currentQueryUserName: string | null;
  keyFiguresData: getKeyFigures | undefined;
  showFigures: [boolean, Dispatch<SetStateAction<boolean>>];
  getCallListQuery: LazyQueryExecFunction<getCallList, OperationVariables>;
};

const Impersonation: FC<ImpersonateProps> = (props) => {
  const [openD, setOpenD] = React.useState<boolean>(false);
  const {
    currentUser,
    impersonating,
    currentQueryUserName,
    keyFiguresData,
    showFigures,
    getCallListQuery,
  } = props;
  const [showKeyFigures, setShowKeyFigures] = showFigures;

  useEffect(() => {
    if (
      keyFiguresData?.myBusinessTradingPartnersSimpleKeyFigures?.result
        ?.length === 1
    ) {
      setShowKeyFigures(true);
    }
    setShowKeyFigures(false);
  }, [
    keyFiguresData?.myBusinessTradingPartnersSimpleKeyFigures?.result?.length,
    setShowKeyFigures,
    showKeyFigures,
  ]);

  const title = impersonating
    ? `${currentUser} impersonates ${currentQueryUserName}`
    : currentUser;

  const handleClickOpen = () => {
    setOpenD(true);
  };

  const handleClose = () => {
    setOpenD(false);
  };

  const renderImpersonateMenu = () => {
    return openD ? (
      <UserToImpersonateMenu
        showImpersonateDialog={openD}
        handleClose={handleClose}
        userImpersonating={impersonating}
        keyFiguresData={keyFiguresData}
        getCallListQuery={getCallListQuery}
      />
    ) : null;
  };

  return (
    <>
      <IconButton
        sx={{
          margin: 0,
          minHeight: 50,
          maxHeight: 50,
        }}
        color="secondary"
        aria-haspopup="true"
        onClick={handleClickOpen}
      >
        <Tooltip title={title || ''}>
          <PeopleAltIcon
            sx={impersonating ? { color: 'success.main' } : { color: 'white' }}
          />
        </Tooltip>
      </IconButton>
      {renderImpersonateMenu()}
    </>
  );
};

export default Impersonation;
