export const SHOW_BUSINESS_TRADING_PARTNER_MY_CUSTOMERS =
  'SHOW_BUSINESS_TRADING_PARTNER_MY_CUSTOMERS';
export const BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_SELECTION_CHANGED =
  'BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_SELECTION_CHANGED';
export const BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_SORT_BY =
  'BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_SORT_BY';
export const BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_SIMPLE_SEARCH =
  'BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_SIMPLE_SEARCH';
export const BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_QUICK_NOTES =
  'BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_QUICK_NOTES';
export const GET_BTP_SCROLL_VALUE = 'GET_BTP_SCROLL_VALUE';
export const REFRESH_WHEN_REGISTER_CALL = 'REFRESH_WHEN_REGISTER_CALL';
export const SHOW_KEY_FIGURES = 'SHOW_KEY_FIGURES';
export const BTP_CLUSTER_UPDATE = 'BTP_CLUSTER_UPDATE';
export const RESET_BTP_DATA = 'RESET_BTP_DATA';
export const SORT_BY_COLUMN_AND_DIRECTION = 'SORT_BY_COLUMN_AND_DIRECTION';
