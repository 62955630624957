import React, { FC, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import ReminderDialog from '../../../components/Reminders/ReminderDialog';
import { CallListResult_result } from './__generated__/CallListResult';

type ComponentProps = {
  row: CallListResult_result;
};

const CallListAction: FC<ComponentProps> = ({ row }) => {
  const isCreateReminderAvailable =
    row.contactTypeDisplayName === 'BTP' ||
    row.contactTypeDisplayName === 'Ship To';
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return isCreateReminderAvailable ? (
    <Tooltip title="Add reminder">
      <span>
        <IconButton onClick={() => setIsOpenDialog(true)}>
          <EventIcon fontSize="small" />
        </IconButton>

        {isOpenDialog && (
          <ReminderDialog
            handleCloseDialog={() => setIsOpenDialog(false)}
            publicIdBTP={row.entityPublicId}
            nameBTP={row.name}
          />
        )}
      </span>
    </Tooltip>
  ) : null;
};

export default CallListAction;
