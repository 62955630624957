import React, {
  FC,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { ValidationError } from 'yup';
import { CustomDialog } from '@bestseller-bit/sales-and-invoicing.ui-elements.dialog';
import { gql, useMutation } from '@apollo/client';
import { useFeedbackContext } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import { rootStateInterface } from '../../../../reducers';
import {
  contactPhoneListValidation,
  nameSchema,
} from '../../../../validation/validation';

import {
  updateBTPMyContact,
  updateBTPMyContactVariables,
} from './__generated__/updateBTPMyContact';
import { UpdatedContactPhone } from '../../../../__generated__/globalTypes';
import { getAllActiveCountries_findByCountryColumns_result } from '../../../app/_MainApp/__generated__/getAllActiveCountries';
import { getCallList_getMyCallList_result } from '../call.list/__generated__/getCallList';

type stateProps = {
  rowData: getCallList_getMyCallList_result | undefined;
  openModal: [boolean, Dispatch<SetStateAction<boolean>>];
  onClose: Function;
  getCallListQuery: any;
};

const EditExternalContact: FC<stateProps> = ({
  rowData,
  openModal,
  onClose,
  getCallListQuery,
}) => {
  const initialCountryCode: { countryPublicId: string }[] = [];
  const initialPhoneNumber: { phoneNumber: string }[] = [];
  const initialPhoneListArray: UpdatedContactPhone[] = [];
  const initialPhonePublicId: { publicId: string }[] = [];
  const [open, setOpen] = openModal;

  if (rowData && rowData.contactPhoneNumber !== undefined) {
    rowData?.contactPhoneNumber?.forEach(
      (cPhone: UpdatedContactPhone, index: number) => {
        initialPhonePublicId[index] = { publicId: cPhone.publicId || '' };
        initialCountryCode[index] = {
          countryPublicId: cPhone.countryPublicId,
        };
        initialPhoneNumber[index] = { phoneNumber: cPhone.phoneNumber };
        initialPhoneListArray[index] = {
          countryPublicId: initialCountryCode[index].countryPublicId,
          phoneNumber: initialPhoneNumber[index].phoneNumber,
          publicId: initialPhonePublicId[index].publicId,
        };
      }
    );
  }

  const { addErrorMessage } = useFeedbackContext();
  const [nameValue, setNameValue] = useState<string | undefined>(
    rowData?.contactName
  );
  const [nameErrorMsg, setNameErrorMsg] = useState<string | undefined>('');
  const [allowToSave, setAllowToSave] = useState(false);
  const [contactPhoneList, setContactPhoneList] = useState<
    UpdatedContactPhone[]
  >(initialPhoneListArray);
  const [countryValue, setCountryValue] =
    useState<{ countryPublicId: string }[]>(initialCountryCode);
  const [phoneValue, setPhoneValue] =
    useState<{ phoneNumber: string }[]>(initialPhoneNumber);
  const [phoneValidation, setPhoneValidation] = useState<(string | string[])[]>(
    ['VALID', [''], ['']]
  );
  const { countries } = useSelector(
    (state: rootStateInterface) => state.application.shared.bestoneCommon
  );

  const handleClose = () => {
    onClose();
  };

  const [updateExternalContact] = useMutation<
    updateBTPMyContact,
    updateBTPMyContactVariables
  >(updateBTPMyContactMutation, {
    onCompleted: () => {
      getCallListQuery();
    },
    onError: (e) => addErrorMessage(e.message, updateBTPMyContactMutation),
  });

  const handleUpdateContact = () => {
    const updateContactParams: updateBTPMyContactVariables = {
      contactPublicId: rowData?.publicId || '',
      contactInfo: {
        contactName: nameValue || '',
        contactPhoneList,
        contactEmailAddres: '',
      },
    };
    updateExternalContact({
      variables: updateContactParams,
    });
    handleClose();
  };

  function validateName(name: string) {
    setNameValue(name);
    if (name === '') {
      setNameErrorMsg('Contact name can not be empty.');
    } else {
      setNameErrorMsg('');
    }
  }

  useEffect(() => {
    if (nameValue !== '' && contactPhoneList[0].countryPublicId !== '') {
      setAllowToSave(true);
    } else {
      setAllowToSave(false);
    }
  }, [nameValue, allowToSave, contactPhoneList]);

  useEffect(() => {
    if (open === false) {
      setNameValue('');
      setNameErrorMsg('');
      setAllowToSave(false);
    }
  }, [open, setOpen]);

  const handleCountryChange = (event: SelectChangeEvent<{ value: string }>) => {
    const newCountryArray = [...countryValue];
    newCountryArray[0] = {
      countryPublicId: event.target.value ? event.target.value.toString() : '',
    };
    setCountryValue(newCountryArray);
    const newPhoneListArray = [...contactPhoneList];
    newPhoneListArray[0] = {
      countryPublicId: event.target.value ? event.target.value.toString() : '',
      phoneNumber:
        contactPhoneList[0] !== undefined
          ? contactPhoneList[0].phoneNumber
          : '',
      publicId:
        contactPhoneList[0] !== undefined ? contactPhoneList[0].publicId : '',
    };
    setContactPhoneList(newPhoneListArray);
  };

  const handlePhoneChange = (event: React.ChangeEvent<{ value: string }>) => {
    const phoneNumber = event.target.value.trim();
    const newPhoneArray = [...phoneValue];
    newPhoneArray[0] = { phoneNumber };
    setPhoneValue(newPhoneArray);
    const newPhoneListArray: UpdatedContactPhone[] = [...contactPhoneList];
    newPhoneListArray[0] = {
      countryPublicId:
        contactPhoneList[0] !== undefined
          ? contactPhoneList[0].countryPublicId
          : countryValue !== undefined
          ? countryValue[0].countryPublicId
          : '',
      phoneNumber,
      publicId:
        contactPhoneList[0] !== undefined ? contactPhoneList[0].publicId : '',
    };
    setContactPhoneList(newPhoneListArray);
  };

  useEffect(() => {
    if (
      contactPhoneList[0]?.countryPublicId === '' &&
      contactPhoneList[0]?.phoneNumber === undefined
    )
      setPhoneValidation(['VALID', [''], ['']]);
    else setPhoneValidation(contactPhoneListValidation(contactPhoneList));
  }, [contactPhoneList]);

  useEffect(() => {
    try {
      nameSchema.validateSync({ name: nameValue });
      setNameErrorMsg('');
    } catch (error) {
      if (error instanceof ValidationError) setNameErrorMsg(error.message);
    }
  }, [nameValue]);

  return (
    <CustomDialog
      maxWidth="sm"
      minHeight="auto"
      openState={openModal}
      onClose={handleClose}
      title="Edit external contact"
      buttons={{
        buttons: [
          {
            label: 'Update',
            disabled: allowToSave !== true,
            onClick: handleUpdateContact,
          },
          {
            label: 'Close',
            onClick: handleClose,
          },
        ],
      }}
    >
      <TextField
        error={
          !!(
            nameErrorMsg === 'Contact name can not be empty.' &&
            nameValue === ''
          )
        }
        onBlur={(event) => validateName(event.target.value)}
        helperText={nameErrorMsg}
        value={nameValue}
        autoFocus
        margin="dense"
        id="Contact_Name"
        label="Contact Name"
        type="text"
        fullWidth
        onChange={(event) => validateName(event.target.value)}
      />
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12} md={5}>
          <FormControl
            margin="dense"
            fullWidth
            variant="outlined"
            error={!!(phoneValidation[2][0] === 'Select country code')}
          >
            <InputLabel>Country code</InputLabel>
            <Select
              label="Country code"
              value={
                contactPhoneList && contactPhoneList[0] !== undefined
                  ? contactPhoneList[0].countryPublicId
                  : ''
              }
              onChange={(e: SelectChangeEvent<{ value: string }>) =>
                handleCountryChange(e)
              }
              variant="outlined"
            >
              {Array.isArray(countries) &&
                countries.map(
                  (item: getAllActiveCountries_findByCountryColumns_result) => (
                    <MenuItem
                      key={item.name}
                      sx={{ fontsize: 'body1' }}
                      value={item.countryIsoAlpha3}
                    >
                      <Typography sx={{ fontsize: 'body1' }}>
                        {`${item.name}  :  ${item.intAccessCode}`}
                      </Typography>
                    </MenuItem>
                  )
                )}
            </Select>
            <FormHelperText>
              {phoneValidation[2][0] === 'Select country code'
                ? 'Select country code'
                : phoneValidation[2][0] === 'Select -None- when phone is empty'
                ? 'Select -None- when phone is empty'
                : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            value={
              contactPhoneList[0] && contactPhoneList[0] !== undefined
                ? contactPhoneList[0].phoneNumber
                : ''
            }
            error={phoneValidation[1][0] === 'Only numbers are allowed'}
            helperText={phoneValidation[1][0]}
            margin="dense"
            id="Contact_Phone"
            label="Contact Phone"
            type="text"
            fullWidth
            onChange={(event) => handlePhoneChange(event)}
            disabled={
              !contactPhoneList[0] ||
              contactPhoneList[0]?.countryPublicId === ''
            }
          />
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default EditExternalContact;

const updateBTPMyContactMutation = gql`
  mutation updateBTPMyContact(
    $contactPublicId: String!
    $contactInfo: UpdatedContact!
  ) {
    BTP_UPDATE_CONTACT: updateContactNode(
      contactPublicId: $contactPublicId
      contactInfo: $contactInfo
    ) {
      contactPublicId
      contactName
      contactPhoneList {
        publicId
        countryPublicId
        countryCode
        phoneNumber
        isPrimaryPhone
        changedBy
        changedDate
      }
      contactEmailAddress
    }
  }
`;
