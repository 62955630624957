import React, { FC, useState } from 'react';
import {
  Typography,
  InputBase,
  Tooltip,
  Zoom,
  TextField,
  Hidden,
  SxProps,
} from '@mui/material';
import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';
import { CustomDialog } from '@bestseller-bit/sales-and-invoicing.ui-elements.dialog';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useFeedbackContext } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import { UpdatableCallList } from '../../../../../__generated__/globalTypes';
import { GET_CALL_LIST_QUERY } from '../../call.list/CallList';
import { addMyContactBTPToCallList } from '../../../../app/_CustomerMainPage/__generated__/addMyContactBTPToCallList';
import {
  ADD_CONTACT_SHIP_TO_TO_CALL_LIST_MUTATION,
  ADD_MY_CONTACT_BTP_TO_CALL_LIST_MUTATION,
} from '../../../../app/_CustomerMainPage/CustomerMainPage';
import { addContactShipToToCallList } from '../../../../app/_CustomerMainPage/__generated__/addContactShipToToCallList';
import { CallListResultFragment } from '../../call.list/callListResultFragment';
import { addBusinessTradingPartnerToCallList } from './__generated__/addBusinessTradingPartnerToCallList';
import { addShipToToCallListMutation } from './__generated__/addShipToToCallListMutation';

const MAX_NOTE_LENGHT: number = 20;

const classes: { [k: string]: SxProps } = {
  InputBase: {
    marginTop: '0px',
    width: '100%',
    textOverflow: 'ellipsis',
    fontSize: 12,
    overflow: 'hidden',
    paddingRight: '1.2rem',
  },
  popper: {
    '& .MuiTooltip-popper': {
      color: 'blue',
    },
  },
};

type EditTextCellPageProps = {
  cellRow?: any;
  cellData?: string;
  addExternalContactToCallListMutation: any;
};

const EditNoteCallList: FC<EditTextCellPageProps> = ({
  cellRow,
  cellData,
  addExternalContactToCallListMutation,
}) => {
  const [oldValue, setOldValue] = useState(cellData);
  const [newValue, setNewValue] = useState(oldValue);
  const [open, setOpen] = useState(false);

  const { addErrorMessage } = useFeedbackContext();

  const [addBusinessTradingPartnerToCallListMutation] =
    useMutation<addBusinessTradingPartnerToCallList>(
      ADD_BTP_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(e.message, ADD_BTP_TO_CALL_LIST_MUTATION);
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const [addShiptoToCallListMutation] =
    useMutation<addShipToToCallListMutation>(
      ADD_SHIP_TO_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(e.message, ADD_SHIP_TO_TO_CALL_LIST_MUTATION);
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const [addMyContactBTPToCallListMutation] =
    useMutation<addMyContactBTPToCallList>(
      ADD_MY_CONTACT_BTP_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(e.message, ADD_MY_CONTACT_BTP_TO_CALL_LIST_MUTATION);
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const [addContactShipToToCallListMutation] =
    useMutation<addContactShipToToCallList>(
      ADD_CONTACT_SHIP_TO_TO_CALL_LIST_MUTATION,
      {
        onError: (e) => {
          addErrorMessage(e.message, ADD_CONTACT_SHIP_TO_TO_CALL_LIST_MUTATION);
        },
        refetchQueries: [GET_CALL_LIST_QUERY],
      }
    );

  const isMobile = useIsMobile();

  const handleEdit = (noteValue: string) => {
    setNewValue(noteValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setNewValue(oldValue);
    setOpen(false);
  };

  const onEdit = () => {
    const updateParams: UpdatableCallList = {
      ownerPublicId: cellRow.callListRelationPublicId, // TODO: Type for cellRow and cellData
      note: newValue,
      lastCallDate: cellRow.lastCallDate,
    };
    if (cellRow.contactType === 'BUSINESS_TRADING_PARTNER') {
      addBusinessTradingPartnerToCallListMutation({
        variables: { updatableCallList: updateParams },
      });
    } else if (cellRow.contactType === 'SHIP_TO') {
      addShiptoToCallListMutation({
        variables: { updatableCallList: updateParams },
      });
    } else if (
      cellRow.contactType === 'CONTACT' &&
      cellRow.contactTypeDisplayName === 'BTP'
    ) {
      addMyContactBTPToCallListMutation({
        variables: { updatableCallList: updateParams },
      });
    } else if (
      cellRow.contactType === 'CONTACT' &&
      cellRow.contactTypeDisplayName === 'Ship To'
    ) {
      addContactShipToToCallListMutation({
        variables: { updatableCallList: updateParams },
      });
    } else if (
      cellRow.contactType === 'CONTACT' &&
      cellRow.contactTypeDisplayName === 'External Contact'
    ) {
      addExternalContactToCallListMutation({
        variables: {
          updatableCallList: updateParams,
        },
      });
    }
    setOldValue(newValue);
    handleClickClose();
  };

  return (
    <>
      {oldValue && oldValue.length > 0 ? (
        <Hidden mdUp implementation="js">
          <Typography onClick={handleClickOpen}>{oldValue}</Typography>
        </Hidden>
      ) : (
        <Hidden mdUp implementation="js">
          <InputBase inputComponent="input" onClick={handleClickOpen} />
        </Hidden>
      )}
      <Hidden mdDown implementation="js">
        <Tooltip
          title={oldValue ? (oldValue.length > 0 ? oldValue || '' : '') : ''}
          TransitionComponent={Zoom}
          sx={classes.popper}
          disableHoverListener={oldValue ? oldValue.length === 0 : true}
          disableFocusListener
        >
          <InputBase
            inputComponent="input"
            onClick={handleClickOpen}
            sx={
              (classes.InputBase,
              {
                caretColor: 'transparent',
              })
            }
            type="text"
            name={cellRow.name}
            value={
              oldValue !== null
                ? oldValue !== undefined && oldValue.length > MAX_NOTE_LENGHT
                  ? `${oldValue.substring(0, MAX_NOTE_LENGHT)} ...`
                  : oldValue
                : ''
            }
            margin="dense"
          />
        </Tooltip>
      </Hidden>
      <CustomDialog
        maxWidth="sm"
        minHeight="auto"
        openState={[open, setOpen]}
        onClose={handleClickClose}
        title="Edit note for"
        buttons={{
          buttons: [
            {
              label: 'Cancel',
              onClick: handleClickClose,
            },
            {
              label: 'Save',
              onClick: onEdit,
            },
          ],
        }}
      >
        <Typography variant="h3">
          {cellRow.name ? cellRow.name : cellRow.contactName}
        </Typography>
        <TextField
          id="standard-multiline-flexible"
          autoFocus
          onFocus={(e) => {
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            );
          }}
          fullWidth
          multiline
          maxRows={isMobile ? '15' : '6'}
          value={newValue || ''}
          onChange={(event) => handleEdit(event.target.value)}
          rows="6"
          variant="outlined"
        />
      </CustomDialog>
    </>
  );
};

export const ADD_BTP_TO_CALL_LIST_MUTATION = gql`
  mutation addBusinessTradingPartnerToCallList(
    $updatableCallList: [UpdatableCallList]
  ) {
    addBusinessTradingPartnerToCallList(updatableCallList: $updatableCallList) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;

export const ADD_SHIP_TO_TO_CALL_LIST_MUTATION = gql`
  mutation addShipToToCallListMutation(
    $updatableCallList: [UpdatableCallList]
  ) {
    addShipToToCallList(updatableCallList: $updatableCallList) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;

export default EditNoteCallList;
