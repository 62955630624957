import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { datadogRum } from '@datadog/browser-rum';
import App from './App';
import environment from './utility/environment';
import { isProd, isTest } from './utility/system';

const container = document.getElementById('root');
const root = createRoot(container!);

if (isProd() || isTest()) {
  datadogRum.init({
    applicationId: '9207abcc-375a-4b72-8597-ca80cc96e9bb',
    clientToken: 'pubed46ac339388110611e8072c1e001e1a',
    site: 'datadoghq.eu',
    service: 'sales.salestool',
    env: environment,
    version: `${process.env.REACT_APP_APP_VERSION}`,
    sampleRate: 100,
    trackInteractions: true,
    trackUserInteractions: true,
    trackFrustrations: true,
  });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
